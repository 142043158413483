<script setup lang="ts">
const props = defineProps<{
	inline?: boolean
}>();
</script>

<template>
	<div class="dropdown" :class="{ 'dropdown--inline': props.inline }">
		<ul class="dropdown__list">
			<slot />
		</ul>
	</div>
</template>
