<script setup lang="ts">
const props = defineProps<{
	label?: string
	icon?: string
	linkTo?: string | object
	severity?: 'secondary' | 'success' | 'error' | string
	disabled?: boolean
	loading?: boolean
}>();
</script>

<template>
	<li class="dropdown__item">
		<NuxtLink
			class="dropdown__link"
			:class="{
				[`dropdown__link--${props.severity}`]: props.severity,
				'dropdown__link--disabled': props.disabled,
			}"
			:to="props.linkTo"
			v-if="!$slots.default && props.linkTo"
		>
			<span v-if="props.label">{{ props.label }}</span>
			<Icon :name="props.icon" v-if="props.icon && !props.loading" />
			<span class="dropdown__loading loading" v-else-if="props.loading"></span>
		</NuxtLink>

		<button
			type="button"
			class="dropdown__link"
			:class="{
				[`dropdown__link--${props.severity}`]: props.severity,
				'dropdown__link--disabled': props.disabled,
			}"
			:disabled="props.disabled"
			v-else-if="!$slots.default"
		>
			<span v-if="props.label">{{ props.label }}</span>
			<Icon :name="props.icon" v-if="props.icon && !props.loading" />
			<span class="dropdown__loading loading" v-else-if="props.loading"></span>
		</button>

		<slot v-else />
	</li>
</template>
